<template>
	<el-dialog title="地址簿" top="1vh" width="60%" :visible.sync="is_show_in_Page">

		<!-- 搜索条件表单 -->
		<el-form size="small"  label-width="100px" style="margin-top:-15px;display:flex">
			<el-form-item label="地址类型">
				<el-select v-model="form.type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="发货地" value="1"></el-option>
					<el-option label="卸货地" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="关键字搜索">
				<el-input class="el_input" v-model="form.keywords" placeholder="关键字搜索" clearable></el-input>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="load_data">查询</el-button>
			</el-form-item>
		</el-form>
		
		<!-- 列表 -->
		<div style="margin-top:10px;height:500px">
			<el-table :data="addr_book_list" :border="true" :stripe="true" size="small" height="100%" v-loading="loading">
				<el-table-column label="添加时间" prop="creat_time_text" width="145"></el-table-column>
				<el-table-column label="地址">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.prov_name}} / {{scope.row.city_name}} / {{scope.row.county_name}} / {{scope.row.addr_info}}</div>
					</template>
				</el-table-column>
				<el-table-column label="详细地址" prop="link_info_text" width="190"></el-table-column>
				<el-table-column label="类型" prop="type_text" width="80"></el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="90">
					<template slot-scope="scope">
						<el-button @click="addr_choosed(scope.row)" size="mini" type="text">使用</el-button>
						<el-button @click="addr_del(scope.row)" size="mini" type="text" style="color:red">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</el-dialog>
</template>
<script>
	export default {
		props:{
			type:Number,//类型
			user_num:String,//用户编号
			is_show:Number,//是否显示
		},
		data() {
			return {

				//列表是否显示
				is_show_in_Page:false,

				//地址簿列表
				addr_book_list:[],

				//加载状态
				loading: false,//loading特效

				//查询表单
				form:{

					//类型(1:发货地,2:卸货地,3...)
					type:'',

					keywords:'',//关键字搜索
				}
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开界面
					this.is_show_in_Page=true;

					//如果没有用户编号则不读取数据
					if(!this.user_num){
						return;
					}

					//类型
					this.form.type=`${this.type}`

					//读取
					this.load_data();

				}else{

					//关闭界面
					this.is_show_in_Page=false
				}
			},
		},
		methods: {

			//使用地址
			addr_choosed(item){

				//询问
				this.$my.other.confirm({
					content:'确定使用此地址',
					confirm:()=>{
						
						//通知
						this.$emit('choosed',{
							...item
						})

						//关闭
						this.is_show_in_Page=false
					}
				});
			},

			//删除地址
			addr_del(item){

				//询问
				this.$my.other.confirm({
					content:'确定删除',
					confirm:()=>{
						
						//执行删除
						this.$my.net.req({
							data:{
								mod:'app_user',
								ctr:'addr_book_del_pub',
								user_num:this.user_num,
								id:item.id,
							},
							callback:(data)=>{

								//刷新数据
								this.load_data()

								//反馈数据
								this.$my.other.msg({
									type:'success',
									str:'删除成功'
								});
							}
						});
					}
				});
			},

			//读取数据
			load_data(){

				//读取状态
				this.loading=true

				//读取数据
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'addr_book_list_pub',
						user_num:this.user_num,
						type:this.type,
						...this.form
					},
					callback:(data)=>{

						//预处理
						for(let item of data.list){

							//时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//联系人
							if(item.link_man && item.link_tel){
								item.link_info_text=`${item.link_man}/${item.link_tel}`
							}else if(item.link_man){
								item.link_info_text=`${item.link_man}/无联系人电话`
							}else if(item.link_tel){
								item.link_info_text=`无联系人姓名/${item.link_tel}`
							}else{
								item.link_info_text=`无`
							}

							//类型
							switch(item.type){
								case '1':item.type_text='装货地址';break;
								case '2':item.type_text='卸货地址';break;
							}
						}
						
						//渲染
						this.addr_book_list=data.list
						
						//加载完毕
						this.loading=false
					}
				});
			},
		}
	}
</script>